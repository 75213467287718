export default {
  'auth.add.user': 'Ajouter un Utilisateur',
  'auth.create.account': 'Créer un Compte',
  'auth.create.an.account': 'Créer un Compte',
  'auth.error.username.empty': "Le nom d'utilisateur ne peut pas être vide.",
  'auth.log.in': 'Connexion',
  'auth.login': 'Identifiant',
  'auth.password': 'Mot de Passe',
  'auth.user.accounts': 'Comptes',
  'auth.username': "Nom d'Utilisateur",
  'auth.admin': 'Admin',
  'auth.message.not.admin': "L'Utilisateur n'est pas Administrateur",

  'button.add': 'Ajouter',
  'button.cancel': 'Annuler',
  'button.no': 'Non',
  'button.save.feed': 'Enregistrer',
  'button.save': 'Enregistrer',
  'button.state.adding': 'Ajout...',
  'button.yes': 'Oui',

  'feeds.add.automatic.download.rule': 'Ajouter une règle de téléchargement',
  'feeds.add.feed': 'Ajouter un Flux',
  'feeds.applicable.feed': 'Flux Applicable',
  'feeds.apply.tags': 'Appliquer les Tags',
  'feeds.exclude.pattern': 'Exclure le Motif',
  'feeds.existing.feeds': 'Flux Existants',
  'feeds.existing.rules': 'Règles Existantes',
  'feeds.label': 'Label',
  'feeds.match.count': '{count, plural, =1 {# correspond} other {# correspondent}}',
  'feeds.match.pattern': 'Motif Correspondant',
  'feeds.match': 'Correspondance',
  'feeds.no.feeds.available': 'Aucun flux disponible.',
  'feeds.no.feeds.defined': 'Aucun flux défini.',
  'feeds.no.rules.defined': 'Aucune règle définie.',
  'feeds.regEx': 'RegEx',
  'feeds.select.feed': 'Sélectionner un Flux',
  'feeds.select.interval': 'Intervalle',
  'feeds.start.on.load': 'Démarrer Immédiatement',
  'feeds.tabs.download.rules': 'Règles de Téléchargement',
  'feeds.tabs.feeds': 'Flux',
  'feeds.tabs.heading': 'Flux de Torrent',
  'feeds.tags': 'Tags',
  'feeds.time.hr': '{durationValue} h',
  'feeds.time.min': '{durationValue} min',
  'feeds.torrent.destination': 'Destination du Torrent',
  'feeds.url': 'URL',
  'feeds.validation.invalid.regular.expression': 'Expression régulière invalide.',
  'feeds.validation.must.select.feed': 'Vous devez choisir un flux.',
  'feeds.validation.must.specify.destination': 'Vous devez définir un emplacement.',
  'feeds.validation.must.specify.label': 'Vous devez définir un label.',
  'feeds.validation.must.specify.valid.feed.url': 'Vous devez définir une URL de flux valide.',

  'filter.all': 'Tout',
  'filter.status.title': 'Filtrer par Status',
  'filter.status.downloading': 'En Téléchargement',
  'filter.status.completed': 'Terminé',
  'filter.status.active': 'Actif',
  'filter.status.inactive': 'Inactif',
  'filter.status.error': 'Erreur',
  'filter.tracker.title': 'Filtrer par Traqueur',
  'filter.tag.title': 'Filtrer par Tag',
  'filter.untagged': 'Sans Tag',

  'locale.language.en': 'Anglais',
  'locale.language.fr': 'Français',
  'locale.language.ko': 'Coréen',
  'locale.language.nl': 'Néerlandais',

  'alert.torrent.add': `L'ajout de {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    } a réussi.`,
  'alert.torrent.add.failed': `L'ajout de {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    } a échoué.`,
  'alert.torrent.move': `Le déplacement de {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    } a réussi.`,
  'alert.torrent.move.failed': `Le déplacement de {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    } a échoué.`,
  'alert.torrent.remove': `La suppression de {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    } a réussi.`,
  'alert.torrent.remove.failed': `La suppression de {countElement} {count, plural,
      =1 {torrent}
      other {torrents}
    } a échoué.`,
  'alert.settings.saved': 'Paramètres modifiés.',

  'priority.dont.download': 'Arrêt',
  'priority.high': 'Haute',
  'priority.low': 'Basse',
  'priority.normal': 'Normale',

  'settings.bandwidth.slots.download.divider.label': 'Diviseur Slots de Réception',
  'settings.bandwidth.slots.download.global.label': 'Slots de Réception (Global)',
  'settings.bandwidth.slots.download.label': 'Slots de Réception par Torrent',
  'settings.bandwidth.slots.heading': 'Configuration des Slots',
  'settings.bandwidth.slots.upload.divider.label': "Diviseur Slots d'Envoi",
  'settings.bandwidth.slots.upload.global.label': "Slots d'Envoi (Global)",
  'settings.bandwidth.slots.upload.label': "Slots d'Envoi par Torrent",
  'settings.bandwidth.transferrate.dropdown.preset.download.label': 'Pré-réglages du Menu (Réception) :',
  'settings.bandwidth.transferrate.dropdown.preset.upload.label': 'Pré-réglages du Menu (Envoi) :',
  'settings.bandwidth.transferrate.global.throttle.download': 'Limitation Globale de Réception',
  'settings.bandwidth.transferrate.global.throttle.upload': "Limitation Globale d'Envoi",
  'settings.bandwidth.transferrate.heading': 'Configuration Taux de Transfert',

  'settings.connectivity.dht.label': 'Activer le DHT',
  'settings.connectivity.dht.port.label': 'Port DHT',
  'settings.connectivity.dpd.heading': 'Découverte de Pairs Décentralisée',
  'settings.connectivity.incoming.heading': 'Connexions Entrantes',
  'settings.connectivity.ip.hostname.label': "Adresse IP/Nom d'Hôte",
  'settings.connectivity.max.http.connections': 'Connexions HTTP Maximum',
  'settings.connectivity.peer.exchange.label': "Activer l'échange de Pairs",
  'settings.connectivity.peers.desired.label': 'Pairs Désirés',
  'settings.connectivity.peers.heading': 'Pairs',
  'settings.connectivity.peers.max.label': 'Pairs Maximum (Réception)',
  'settings.connectivity.peers.min.label': 'Pairs Minimum (Réception)',
  'settings.connectivity.peers.seeding.max.label': 'Pairs en Partage Maximum (Envoi)',
  'settings.connectivity.peers.seeding.min.label': 'Pairs en Partage Minimum (Envoi)',
  'settings.connectivity.port.open.label': 'Port Ouvert',
  'settings.connectivity.port.randomize.label': 'Port Aléatoire',
  'settings.connectivity.port.range.label': 'Plage de Ports',

  'settings.resources.disk.check.hash.label': 'Vérifier le hash après le Téléchargement',
  'settings.resources.disk.download.location.label': 'Répertoire de Téléchargement par défaut',
  'settings.resources.disk.heading': 'Disque Dur',
  'settings.resources.max.open.files': 'Fichiers Ouverts Maximum',
  'settings.resources.memory.heading': 'Mémoire Vive',
  'settings.resources.memory.max.label': "Limite d'utilisation Mémoire Vive",

  'settings.tabs.bandwidth': 'Bande Passante',
  'settings.tabs.connectivity': 'Connectivité',
  'settings.tabs.heading': 'Paramètres',
  'settings.tabs.resources': 'Ressources',
  'settings.tabs.authentication': 'Authentification',
  'settings.tabs.userinterface': 'Interface',
  'settings.tabs.about': 'À propos',

  'settings.ui.locale': 'Traductions',
  'settings.ui.language': 'Langue',

  'settings.about.flood': 'À propos de Flood',

  'sidebar.button.feeds': 'Flux',
  'sidebar.button.settings': 'Réglages',
  'sidebar.button.speedlimits': 'Limites de Vitesse',

  'sidebar.search.placeholder': 'Rechercher des Torrents',

  'sidebar.transferdata.downloaded': 'Reçu',
  'sidebar.transferdata.uploaded': 'Envoyé',

  'speed.unlimited': 'Illimité',

  'unit.size.byte': 'o',
  'unit.size.kilobyte': 'ko',
  'unit.size.megabyte': 'Mo',
  'unit.size.gigabyte': 'Go',
  'unit.size.terabyte': 'To',
  'unit.speed': '{baseUnit}/s',
  'unit.time.year': 'an',
  'unit.time.week': 'sem',
  'unit.time.day': 'j',
  'unit.time.hour': 'h',
  'unit.time.minute': 'min',
  'unit.time.second': 's',
  'unit.time.infinity': '∞',

  'torrents.add.button.add': 'Ajouter un Torrent',
  'torrents.add.destination.label': 'Destination',
  'torrents.add.destination.placeholder': 'Répertoire de Destination',
  'torrents.add.heading': 'Ajouter des Torrents',
  'torrents.add.start.label': 'Démarrer',
  'torrents.add.tab.file.browse': 'ou cliquez pour rechercher',
  'torrents.add.tab.file.drop': 'Glissez les fichiers ici,',
  'torrents.add.tab.file.title': 'Par Fichier',
  'torrents.add.tab.url.input.placeholder': 'URL du Torrent',
  'torrents.add.tab.url.title': 'Par URL',
  'torrents.add.torrents.label': 'Torrents',

  'torrents.details.actions.pause': 'Pause',
  'torrents.details.actions.start': 'Démarrer',
  'torrents.details.actions.stop': 'Arrêter',
  'torrents.details.details': 'Détails',
  'torrents.details.files': 'Fichiers',
  'torrents.details.general.comment': 'Commentaires',
  'torrents.details.general.connected': '{connected} connectés sur {total}',
  'torrents.details.general.date.added': 'Ajouté',
  'torrents.details.general.date.created': 'Création',
  'torrents.details.general.downloaded': 'Téléchargé',
  'torrents.details.general.free.disk.space': 'Espace Disque Libre',
  'torrents.details.general.hash': 'Hash',
  'torrents.details.general.heading.general': 'Général',
  'torrents.details.general.heading.torrent': 'Torrent',
  'torrents.details.general.heading.tracker': 'Traqueur',
  'torrents.details.general.heading.transfer': 'Transfert',
  'torrents.details.general.location': 'Emplacement',
  'torrents.details.general.none': 'Non',
  'torrents.details.general.peers': 'Pairs',
  'torrents.details.general.scheduler.ignored': 'Ignoré',
  'torrents.details.general.scheduler.obeyed': 'Conforme',
  'torrents.details.general.scheduler': 'Planifié',
  'torrents.details.general.seeds': 'En Partage',
  'torrents.details.general.size': 'Taille',
  'torrents.details.general.tags': 'Tags',
  'torrents.details.general.tracker.message': 'Message du Traqueur',
  'torrents.details.general.type.private': 'Privé',
  'torrents.details.general.type.public': 'Public',
  'torrents.details.general.type': 'Type',
  'torrents.details.peers.no.data': "Il n'y a aucun Pair actif pour ce Torrent.",
  'torrents.details.peers': 'Pairs',
  'torrents.details.selected.files': `{count, plural,
    =1 {{countElement} fichier sélectionné}
    other {{countElement} fichiers sélectionnés}
  }`,
  'torrents.details.selected.files.set.priority': 'Priorité',
  'torrents.details.trackers.no.data': "Il n'y a aucun Traqueur actif pour ce Torrent.",
  'torrents.details.trackers.type': 'Type',
  'torrents.details.trackers': 'Traqueurs',

  'torrents.list.clear.filters': 'Vider les Filtres',
  'torrents.list.context.check.hash': 'Vérifier le Hash',
  'torrents.list.context.move': 'Déplacer',
  'torrents.list.context.pause': 'Pause',
  'torrents.list.context.priority': 'Priorité',
  'torrents.list.context.remove': 'Supprimer',
  'torrents.list.context.set.tags': 'Définir un Tag',
  'torrents.list.context.start': 'Démarrer',
  'torrents.list.context.stop': 'Arrêter',
  'torrents.list.no.torrents': 'Aucun Torrent à afficher.',
  'torrent.list.peers': '{connected} {of} {total}',
  'torrent.list.peers.of': 'sur',

  'torrents.move.button.set.location': 'Emplacement',
  'torrents.move.button.state.setting': 'Réglages...',
  'torrents.move.data.label': 'Déplacer les Données',
  'torrents.move.heading': "Définir l'Emplacement de Téléchargement",

  'torrents.properties.base.path': 'Chemin absolu',
  'torrents.properties.comment': 'Commentaire',
  'torrents.properties.creation.date': 'Date de Création',
  'torrents.properties.date.added': "Date d'Ajout",
  'torrents.properties.download.speed': 'Vitesse de Téléchargement',
  'torrents.properties.download.total': 'Données Reçues',
  'torrents.properties.eta': 'Date de fin estimée',
  'torrents.properties.free.disk.space': 'Espace libre',
  'torrents.properties.hash': 'Hash',
  'torrents.properties.ignore.schedule': 'Ignorer le Planificateur',
  'torrents.properties.is.private': 'Privé',
  'torrents.properties.name': 'Nom des Fichiers',
  'torrents.properties.percentage': 'Pourcentage Complété',
  'torrents.properties.ratio': 'Ratio',
  'torrents.properties.size': 'Taille des Fichiers',
  'torrents.properties.tags': 'Tags',
  'torrents.properties.tracker.message': 'Message du Tracker',

  'torrents.properties.upload.speed': "Vitesse d'Envoi",
  'torrents.properties.upload.total': 'Données Envoyées',

  'torrents.remove.are.you.sure': `Êtes-vous certain(e) de vouloir supprimer {count, plural,
      =0 {aucun torrent}
      =1 {un torrent}
      other {# torrents}
    } ?`,
  'torrents.remove.delete.data': 'Supprimer les données',
  'torrents.remove.error.no.torrents.selected': "Vous n'avez sélectionné aucun torrent.",
  'torrents.remove': 'Supprimer les Torrents',

  'torrents.set.tags.button.set': 'Définir des Tags',
  'torrents.set.tags.heading': 'Définir des Tags',
  'torrents.set.tags.enter.tags': 'Entrer des Tags',

  'torrents.sort.title': 'Trier par',
};
