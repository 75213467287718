import React from 'react';

import BaseIcon from './BaseIcon';

export default class SettingsIcon extends BaseIcon {
  render() {
    return (
      <svg className={`icon icon--settings ${this.props.className}`} viewBox={this.getViewBox()}>
        <path d="M55.63,33.77a1.28,1.28,0,0,1-.93,1.2l-6.17.93a18.92,18.92,0,0,1-1.3,3c1.14,1.63,2.34,3.1,3.57,4.61a1.31,1.31,0,0,1,.33.83,1.12,1.12,0,0,1-.3.77c-0.8,1.07-5.3,6-6.44,6a1.69,1.69,0,0,1-.87-0.3l-4.61-3.6a20.08,20.08,0,0,1-3,1.27,43.68,43.68,0,0,1-1,6.21,1.22,1.22,0,0,1-1.2.93H26.3a1.19,1.19,0,0,1-1.2-1l-0.93-6.14a19.62,19.62,0,0,1-3-1.23l-4.71,3.57a1.28,1.28,0,0,1-.83.3,1.19,1.19,0,0,1-.83-0.37,41.67,41.67,0,0,1-5.5-5.61A1.3,1.3,0,0,1,9,44.38a1.26,1.26,0,0,1,.27-0.77c1.13-1.54,2.37-3,3.5-4.57a17.8,17.8,0,0,1-1.37-3.3l-6.11-.9a1.22,1.22,0,0,1-1-1.2V26.23a1.29,1.29,0,0,1,.9-1.2l6.21-.93A17.66,17.66,0,0,1,12.78,21c-1.13-1.6-2.34-3.1-3.57-4.61a1.23,1.23,0,0,1-.33-0.8,1.29,1.29,0,0,1,.3-0.77c0.8-1.1,5.3-6,6.44-6a1.49,1.49,0,0,1,.87.33l4.6,3.57a20.07,20.07,0,0,1,3-1.27,43.68,43.68,0,0,1,1-6.21,1.22,1.22,0,0,1,1.2-.93H33.7a1.19,1.19,0,0,1,1.2,1l0.93,6.14a19.66,19.66,0,0,1,3,1.23l4.74-3.57a1.13,1.13,0,0,1,.8-0.3,1.32,1.32,0,0,1,.84.33,42.16,42.16,0,0,1,5.51,5.67,1.14,1.14,0,0,1,.23.73,1.26,1.26,0,0,1-.27.77c-1.13,1.53-2.37,3-3.5,4.57a18.51,18.51,0,0,1,1.37,3.27l6.11,0.93a1.22,1.22,0,0,1,1,1.2v7.41ZM30,21.46A8.54,8.54,0,1,0,38.54,30,8.56,8.56,0,0,0,30,21.46Z" />
      </svg>
    );
  }
}
